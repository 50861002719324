// import { mainDataNew } from "./data";

export const getProcessValues = (selectedFormData, userMappingData) => {
  if (selectedFormData && userMappingData) {
    const processList = userMappingData.filter(
      (row) => row.tower === selectedFormData.tower,
    );

    return processList.map((row) => {
      return row?.process;
    });
  }
};

export const getSubProcessValues = (selectedFormData, userMappingData) => {
  if (selectedFormData && userMappingData) {
    const subProcessList = userMappingData.filter(
      (row) =>
        row.tower === selectedFormData.tower &&
        row.process === selectedFormData.process,
    );
    const subProcesses = subProcessList.map((row) => {
      return row?.sub_process;
    });
    return subProcesses;
  }
};

export const getRegionValues = (selectedFormData, userMappingData) => {
  if (selectedFormData && userMappingData) {
    const regionList = userMappingData.filter(
      (row) =>
        row.tower === selectedFormData.tower &&
        row.process === selectedFormData.process &&
        row.sub_process === selectedFormData.subProcess
    );
    const regions = regionList.map((row) => {
      return row?.region;
    });
    return regions;
  }
};

export const getClusterValues = (selectedFormData, userMappingData) => {
  if (selectedFormData && userMappingData) {
    const clusterList = userMappingData.filter(
      (row) =>
        row.tower === selectedFormData.tower &&
        row.process === selectedFormData.process &&
        row.sub_process === selectedFormData.subProcess &&
        row.region === selectedFormData.region
    );
    const clusters = clusterList.map((row) => {
      return row?.cluster;
    });
    return clusters;
  }
};

import { Popover, Fab, IconButton, Box, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { InformationPage } from '../Header/Toolinformation';
import { useState } from 'react';

export const InformationFAB = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <Fab
                color='primary'
                aria-label='add'
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
            >
                <Button variant="outlined" onClick={handleClick} size="small">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Button>

            </Fab>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ height: 600, width: 1000 }}>
                    <InformationPage />
                </Box>
            </Popover>
        </>
    )
}
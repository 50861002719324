import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  Button,
  Box,
  Avatar,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { ratings } from "../Home/data";
import { useEffect, useState, useRef } from "react";
import {
  DataGridPremium,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import { BasicPopover } from "./popIcon";
import * as XLSX from "xlsx";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BorderLinearProgress } from "../components/StyledProgressBar";
import { SimpleSnackbar } from "../components/Snackbar";
import "./survey.css";
import { getCurrentDateTimeFormatted } from "../utils";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import axios from "axios";
import { getTodaysDate } from "./utils";
import { MandateText } from "./mandateplaceholder";
import { InformationFAB } from "../components/InformationFAB";
import { baseURL } from "../routes";

export const Survey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formData =
    location.state.formData || localStorage.getItem("entryPageFormData");
  const [categories, setCategories] = useState([]);
  const [categorySubCategoryData, setCategorySubCategoryData] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [selectedFormData, setSelectedFormData] = useState({
    category: null,
    regionWeightage: null,
  });
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const rowsRef = useRef([]);
  const [progress, setProgress] = useState({
    completed: 0,
    total: 0,
  });
  const [dataAlreadyInserted, setDataAlreadyInserted] = useState([]);
  const dataAlreadyInsertedRef = useRef([]);
  const [resetPage, setResetPage] = useState(false);

  const [snackbarObj, setSnackbarObj] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [userEmail, setUserEmail] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    rowsRef.current = rows;
  }, [rows]);

  useEffect(() => {
    dataAlreadyInsertedRef.current = dataAlreadyInserted;
  }, [dataAlreadyInserted]);

  const getCategorySubCategoryData = async () => {
    try {
      const categoryURL = new URL(baseURL + "/processCategoryMapping");
      const searchParams = new URLSearchParams();
      searchParams.set("process", formData.process);
      searchParams.set("sub_process", formData.subProcess);
      categoryURL.search = searchParams;
      const res = await axios.get(categoryURL);
      if (res.status === 200) {
        const data = res?.data;
        setCategories([...new Set(data.map((row) => row.category))]);
        setCategorySubCategoryData(data);
      }
    } catch (e) {
      setSnackbarObj({
        severity: "error",
        message: `Unable to fetch category mappings ${e}`,
        open: true,
      });
    }
  };

  const getParameterData = async () => {
    try {
      const parameterURL = new URL(baseURL + "/questionCategoryMapping");
      const searchParams = new URLSearchParams();
      searchParams.set("process", formData.process);
      searchParams.set("sub_process", formData.subProcess);
      parameterURL.search = searchParams;
      const res = await axios.get(parameterURL);
      if (res.status === 200) {
        const data = res?.data;
        setParameterData(data);
      }
    } catch (e) {
      setSnackbarObj({
        severity: "error",
        message: `Unable to fetch category mappings ${e}`,
        open: true,
      });
    }
  };

  const getSurveyCompletionData = async () => {
    try {
      const getSurveyCompletionURL = new URL(
        baseURL + "/getSubmittedDataForSurvey"
      );
      const searchParams = new URLSearchParams();
      const getSurveyDataPayload = {
        tower: formData?.tower,
        process: formData?.process,
        sub_process: formData?.subProcess,
        region: formData?.region,
        cluster: formData?.cluster,
        period: formData?.period,
        user_email_id: userEmail,
        category: selectedFormData.category,
        input_data_type: formData?.inputDataType,
      };
      getSurveyCompletionURL.search = searchParams;
      const res = await axios.post(
        getSurveyCompletionURL,
        getSurveyDataPayload
      );
      if (res.status === 200) {
        const data = res?.data;
        setProgress({
          completed: data?.answered_parameters,
          total: data?.total_parameters,
        });
        setDataAlreadyInserted(data?.submitted_main_data);
      }
    } catch (e) {
      setSnackbarObj({
        severity: "error",
        message: `Unable to fetch category mappings ${e}`,
        open: true,
      });
    }
  };

  const handleSubmit = async () => {
    const ignoreKeys = [
      "subCategory",
      "subCategoryWeightage",
      "category",
      "id",
    ];

    if (!selectedFormData.regionWeightage) {
      setSnackbarObj({
        message: `Please enter a weightage value for the region ${formData?.region}`,
        severity: "error",
        open: true,
      });
      return;
    }

    let inputInValidated = false;
    if (rows?.length > 0) {
      /* Run a loop over the rows data to check if all required fields are filled up */
      rows.forEach((subCategoryLevelParameterData) => {
        Object.keys(subCategoryLevelParameterData).forEach((qKey) => {
          if (!ignoreKeys.includes(qKey)) {
            const parameterObj = subCategoryLevelParameterData[qKey];
            console.log(
              parameterObj,
              "Check",
              subCategoryLevelParameterData?.subCategory
            );
            if (
              parameterObj?.textMandatory &&
              parameterObj?.rating !== "N/A" &&
              parameterObj?.answer?.trim() === ""
            ) {
              setSnackbarObj({
                message:
                  "Please answer all mandatory questions by typing in your answers in the text field",
                severity: "error",
                open: true,
              });
              inputInValidated = true;
            }
          }
        });
      });

      if (!inputInValidated) {
        /* Convert rows data to API friendly data for quick insertion */
        let payload = [];
        rows.forEach((subCategoryLevelParameterData) => {
          const tempSubCategory = subCategoryLevelParameterData?.subCategory;
          const tempSubCategoryWeightage =
            subCategoryLevelParameterData?.subCategoryWeightage;
          Object.keys(subCategoryLevelParameterData).forEach((qKey) => {
            if (!ignoreKeys.includes(qKey)) {
              const parameterObj = subCategoryLevelParameterData[qKey];
              const answer = `${parameterObj?.rating}`;
              const comment = parameterObj?.answer;
              if (
                (parameterObj?.textMandatory && comment !== "") ||
                answer !== ""
              ) {
                let tempPayload = {
                  tower: formData?.tower,
                  process: formData?.process,
                  sub_process: formData?.subProcess,
                  region: formData?.region,
                  region_weightage: selectedFormData.regionWeightage,
                  cluster: formData?.cluster,
                  period: formData?.period,
                  user_email_id: userEmail,
                  upload_date: getTodaysDate(),
                  category: selectedFormData.category,
                  sub_category: tempSubCategory,
                  sub_category_weightage: tempSubCategoryWeightage,
                  answer: `${parameterObj?.rating}`,
                  comment: parameterObj?.answer,
                  approver_email_id: "",
                  status: "Pending for Approval",
                  input_data_type: formData?.inputDataType,
                  parameter: parameterObj?.question,
                  updated_by_email_id: userEmail,
                  field_value: parameterObj?.textMandatory,
                };
                payload.push(tempPayload);
              }
            }
          });
        });

        if (payload?.length === 0) {
          setSnackbarObj({
            message: "You need enter feedback for at least one parameter",
            severity: "error",
            open: true,
          });
        } else {
          const insertDataURL = new URL(baseURL + "/insertDataFromSurvey");
          try {
            const res = await axios.post(insertDataURL, payload);
            if (res.status === 200) {
              setSnackbarObj({
                message: res.data.detail,
                severity: "success",
                open: true,
              });
            }
            setResetPage(!resetPage);
          } catch (e) {
            console.error(e);
            setSnackbarObj({
              message: `Error occured. ${e?.response?.data?.detail}`,
              severity: "error",
              open: true,
            });
          }
        }
      }
    } else {
      setSnackbarObj({
        message: "Error occured please contact your admin",
        severity: "error",
        open: true,
      });
    }
  };

  const handleFileUpload = async (event) => {
    if (event) {
      const file = event.target.files[0];
      const fileName = file?.name;
      const fileExt = fileName?.split(".");

      if (
        fileExt[fileExt.length - 1] === "xlsx" ||
        fileExt[fileExt.length - 1] === "xls"
      ) {
        setSelectedFile(null);
        setSelectedFile(file);

        try {
          const fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            // Assuming Sheet1 is the name of the sheet where data is stored
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const parsedData = XLSX.utils
              .sheet_to_json(worksheet, { raw: true })
              .slice(1);

            let questionKeyDict = {};

            if (rows?.length > 0) {
              const firstRow = rows[0];
              Object.keys(firstRow).forEach((qKey) => {
                questionKeyDict[`${qKey}`] = firstRow[qKey].question;
              });
            }

            // Update rows state based on parsed data
            const updatedRows = rows.map((row, rowIndex) => {
              const updatedRow = { ...row };

              let answerKeyCount = 0;
              let answerKeyFix = "__EMPTY";
              Object.keys(row).forEach((key) => {
                if (
                  key !== "id" &&
                  key !== "category" &&
                  key !== "subCategory"
                ) {
                  const ratingKey = questionKeyDict[key];
                  let answerKey = `${answerKeyFix}`;
                  if (answerKeyCount > 0) {
                    answerKey += `_${answerKeyCount}`;
                  }

                  if (parsedData[rowIndex] && parsedData[rowIndex][ratingKey]) {
                    updatedRow[key].rating = parsedData[rowIndex][ratingKey];
                  }

                  if (parsedData[rowIndex] && parsedData[rowIndex][answerKey]) {
                    updatedRow[key].answer = parsedData[rowIndex][answerKey]
                      ? parsedData[rowIndex][answerKey]
                      : "";
                  }
                  answerKeyCount += 1;
                }
              });

              return updatedRow;
            });

            setRows(updatedRows);
          };

          fileReader.readAsArrayBuffer(file);
          setSnackbarObj({
            open: true,
            message: "File uploaded",
            severity: "success",
          });
        } catch (error) {
          setSnackbarObj({
            open: true,
            message: "Unable to parse excel file",
            severity: "error",
          });
        }
      } else {
        setSnackbarObj({
          open: true,
          message: "Invalid file format. Please upload an Excel file ",
          severity: "error",
        });
        console.error("Invalid file format. Please upload an Excel file ");
      }
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/", { state: { authorized: "Unauthorized" } });
    } else {
      setUserEmail(user?.email_id);
    }
    // eslint-disable-next-line
  }, []);

  const hideWatermarkTemporarily = () => {
    let divTags = document.getElementsByTagName("div");
    let searchText = "MUI X Missing license key";
    let matchingElement;

    for (let i = 0; i < divTags.length; i++) {
      if (divTags[i].textContent === searchText) {
        matchingElement = divTags[i];
        matchingElement.remove();
        break;
      }
    }
  };
  hideWatermarkTemporarily();

  /* UseEffect to fetch sub-category & parameter data based on category */
  useEffect(() => {
    if (formData) {
      getCategorySubCategoryData();
      getParameterData();
    }

    // eslint-disable-next-line
  }, [selectedFormData, resetPage]);

  const handleValueChange = (value, ansKey, type, params) => {
    const id = params.id;
    const weightageObj =
      type === "weightage" ? { subCategoryWeightage: value } : null;
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        if (row.id === id) {
          let tempRow = {};
          if (weightageObj) {
            tempRow = {
              ...row,
              ...weightageObj,
            };
          } else {
            tempRow = {
              ...row,
              [ansKey]: {
                ...row[ansKey],
                [type]: value,
              },
            };
          }

          return tempRow;
        }
        return row;
      });
      return updatedRows;
    });
  };

  const handleTextFieldBlur = (
    answerValue,
    ansKey,
    params,
    type = "answer"
  ) => {
    handleValueChange(answerValue, ansKey, type, params);
  };

  // Custom cell renderer function
  const renderCell = (params) => {
    const ansKey = params?.field;
    const ratingValue = params?.row[ansKey]?.rating;
    const answerValue = params?.row[ansKey]?.answer;
    const textRequired = params?.row[ansKey]?.textMandatory;
    const handleTextFieldKeyDown = (event) => {
      if (event.code === "Space") {
        event.stopPropagation();
      }
    };

    return (
      <>
        <Select
          key={`${ansKey}-${ratingValue}`}
          size="small"
          sx={{
            minWidth: 75,
          }}
          placeholder="rate"
          value={ratingValue}
          onChange={(event) =>
            handleValueChange(event.target.value, ansKey, "rating", params)
          }
        >
          {ratings.map((ratingObj) => {
            return (
              <MenuItem key={ratingObj?.rating} value={ratingObj?.rating}>
                {ratingObj?.rating}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          label={textRequired && ratingValue !== "N/A" ? <MandateText /> : null}
          placeholder="type here..."
          size="small"
          multiline
          sx={{ width: 190, marginTop: "5px", marginLeft: "7px" }}
          onKeyDown={handleTextFieldKeyDown}
          value={answerValue}
          onChange={(event) =>
            handleTextFieldBlur(event.target.value, ansKey, params, "answer")
          }
        />
      </>
    );
  };

  // Custom cell renderer function for weightage textbox
  const renderCellWeightage = (params) => {
    const ansKey = params?.field;
    const subCategoryWeightage = params?.row?.subCategoryWeightage;

    const handleTextFieldKeyDown = (event) => {
      if (event.code === "Space") {
        event.stopPropagation();
      }
    };

    return (
      <TextField
        size="small"
        sx={{ width: 70, marginTop: "5px", marginLeft: "5px" }}
        onKeyDown={handleTextFieldKeyDown}
        type="number"
        value={subCategoryWeightage}
        onChange={(event) =>
          handleTextFieldBlur(event.target.value, ansKey, params, "weightage")
        }
      />
    );
  };

  const getInsertedDataForParam = (parameter, subCat) => {
    let answerText = null;
    let comment = null;
    let textMandatory = null;

    if (dataAlreadyInserted.length > 0) {
      dataAlreadyInserted.forEach((row) => {
        if (row?.parameter === parameter && row?.sub_category === subCat) {
          answerText = row?.answer;
          comment = row?.comment;
          textMandatory = row?.field_value;
        }
      });
    }
    return { answerText, comment, textMandatory };
  };

  /* UseEffect to generate columns and row data */
  useEffect(() => {
    if (selectedFormData.category) {
      getSurveyCompletionData().then(() => {
        const tempSubCategories = [
          ...new Set(
            categorySubCategoryData
              .filter((item) => item.category === selectedFormData.category)
              .map((item) => item.sub_category)
          ),
        ];
        setSubCategories(tempSubCategories);

        // Generate columns
        let columns = [
          {
            field: "subCategory",
            headerName: "Sub Category",
            minWidth: 250,
            pinned: "left",
            sortable: false,
          },
          {
            field: "subCategoryWeightage",
            headerName: "Weightage",
            minWidth: 100,
            pinned: "left",
            sortable: false,
            renderCell: renderCellWeightage,
          },
        ];

        let colQKey = 1;
        parameterData.forEach((parameterObj) => {
          const parameterStyleType =
            parameterObj?.parameter_category?.toLowerCase() ===
            "standardization"
              ? "standardization-header"
              : "automation-header";
          const parameterCol = {
            field: `Q${colQKey}`,
            headerName: parameterObj.parameter,
            minWidth: 300,
            headerClassName: `multi-line-header ${parameterStyleType}`,
            sortable: false,
            renderCell: renderCell,
          };
          columns.push(parameterCol);
          colQKey += 1;
        });

        setColumns(columns);
      });
    } else if (selectedFormData.category === null) {
      setColumns([]);
    }
    // eslint-disable-next-line
  }, [selectedFormData?.category, resetPage]);

  useEffect(() => {
    /* Generate rows */
    let rows = [];
    let count = 0;

    if (columns?.length > 0 && subCategories?.length > 0) {
      subCategories.forEach((subCat) => {
        let row = {
          id: count,
          category: selectedFormData.category,
          subCategory: subCat,
          subCategoryWeightage: 0,
        };
        let questionCount = 1;
        parameterData?.forEach((parameterObj) => {
          const { answerText, comment, textMandatory } =
            getInsertedDataForParam(parameterObj?.parameter, subCat);

          row[`Q${questionCount}`] = {
            question: parameterObj?.parameter,
            textMandatory: textMandatory ?? parameterObj?.mandate_text,
            rating: answerText ?? "",
            answer: comment ?? "",
          };
          questionCount += 1;
        });
        rows.push(row);
        count += 1;
      });
      setRows(rows);
    } else {
      setRows([]);
    }
  }, [columns, subCategories]);

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Survey Data");

    // Create a hidden sheet with the rating values
    const hiddenSheet = workbook.addWorksheet("HiddenSheet");
    // hiddenSheet.state = "veryHidden";

    ratings.forEach((ratingObj, index) => {
      hiddenSheet.getCell(`A${index + 1}`).value = ratingObj.rating;
    });

    const header = [];
    const subHeader = ["id", "category", "subCategory"];

    // Assuming all rows have the same structure for questions
    // Extract headers and subheaders from the first row
    if (rows.length > 0) {
      const firstRow = rows[0];
      Object.keys(firstRow).forEach((key) => {
        if (key !== "id" && key !== "category" && key !== "subCategory") {
          header.push({ label: firstRow[key].question, span: 2 });
          subHeader.push(`${key}_rating`);
          subHeader.push(`${key}_answer`);
        }
      });
    }

    const transformedData = rows.map((row) => {
      const transformedRow = {
        id: row.id,
        category: row.category,
        subCategory: row.subCategory,
      };
      Object.keys(row).forEach((key) => {
        if (key !== "id" && key !== "category" && key !== "subCategory") {
          transformedRow[`${key}_rating`] = row[key]?.rating;
          transformedRow[`${key}_answer`] = row[key]?.answer;
        }
      });
      return transformedRow;
    });

    // Add headers and subheaders to worksheet
    const headerRow = worksheet.addRow(subHeader);
    headerRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFEEEEEE" },
        bgColor: { argb: "FFEEEEEE" },
      };
      cell.font = {
        bold: true,
      };
    });

    header.forEach((item, index) => {
      const colStart = 4 + index * 2; // Starting column for each question
      worksheet.mergeCells(1, colStart, 1, colStart + 1);
      worksheet.getCell(1, colStart).value = item.label;
      worksheet.getCell(2, colStart).value = "Rating";
      worksheet.getCell(2, colStart + 1).value = "Answer";
    });

    // Add rows to worksheet
    transformedData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Define the range for the ratings dropdown
    const ratingsRange = ratings.map((ratingObj) => ratingObj.rating).join(",");

    // Add data validation for rating columns
    for (let rowIndex = 3; rowIndex <= transformedData.length + 2; rowIndex++) {
      for (let colIndex = 4; colIndex <= subHeader.length; colIndex += 2) {
        const cell = worksheet.getCell(rowIndex, colIndex); // Adjusting for zero-based indexing
        cell.dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`"${ratingsRange}"`],
        };
      }
    }

    // Export workbook to Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `survey_data_${
        selectedFormData.category
      }_${getCurrentDateTimeFormatted()}.xlsx`
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid container flex>
        <Grid item xs={6} textAlign="start">
          <Button onClick={() => exportToExcel()} variant="outlined">
            Export Excel Template
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          pt={0.75}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "#033a8838",
              width: 18,
              height: 18,
            }}
            variant="square"
          >
            ‎
          </Avatar>
          &nbsp;
          <Typography color="black" variant="body2">
            {"  "}Standardization
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Avatar
            sx={{
              backgroundColor: "#ff55605e",
              width: 18,
              height: 18,
            }}
            variant="square"
          >
            ‎
          </Avatar>
          &nbsp;
          <Typography color="black" variant="body2">
            {"  "}Automation
          </Typography>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  return (
    <Grid container>
      {/* Category and sub-category selection */}
      <Grid container>
        <Grid item xs={1.5} mt={2} ml={2}>
          <Typography>Category</Typography>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            className="white-background"
            options={categories}
            value={selectedFormData.category}
            onChange={(event, newValue) => {
              setSelectedFormData((prevState) => ({
                ...prevState,
                category: newValue,
              }));
            }}
            sx={{ maxWidth: "200px" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3} mt={2} ml={2}>
          <Typography>
            Enter weightage for <strong>{formData?.region}</strong>
          </Typography>
          <TextField
            size="small"
            type="number"
            className="white-background"
            value={selectedFormData.regionWeightage}
            onChange={(event) => {
              setSelectedFormData((prevState) => ({
                ...prevState,
                regionWeightage: event.target.value,
              }));
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        mr={2}
        mt={1}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Typography variant="h6"> Process: {formData?.process} </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        mr={2}
        gap={2}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload Excel file
          <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
        </Button>
      </Grid>

      {columns.length > 0 && rows.length > 0 && (
        <>
          <Grid
            item
            xs={10.435}
            mx={2}
            px={1}
            pt={1}
            mt={1}
            pl={1}
            sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
          >
            <Typography
              variant="body2"
              sx={{ color: "black", paddingBottom: "3px" }}
            >
              You have answered{" "}
              {Math.round((progress.completed / progress.total) * 100)}% and{" "}
              {progress.completed} out of {progress.total} questions.
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(progress.completed / progress.total) * 100}
            />
          </Grid>
          <Grid
            item
            // mr={2}
            // ml={6.5}
            sx={{ display: "flex", justifyContent: "start" }}
          >
            <BasicPopover />
          </Grid>
          <Grid item xs={12} mt={2} px={2}>
            <Box sx={{ height: 420 }}>
              <DataGridPremium
                columns={columns}
                rows={rows}
                initialState={{
                  pinnedColumns: { left: ["subCategory"] },
                }}
                disableColumnPinning
                showCellVerticalBorder
                autoColumnWidth
                hideFooterPagination
                hideFooter
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
          </Grid>

          <Grid
            container
            xs={12}
            mt={2}
            px={2}
            gap={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button variant="outlined" onClick={() => navigate("/home")}>
              Prev
            </Button>

            {/* <Button variant="outlined">Save</Button> */}
            <Button variant="outlined" onClick={() => handleSubmit()}>
              Submit
            </Button>
          </Grid>
        </>
      )}
      <SimpleSnackbar
        snackBarFunction={setSnackbarObj}
        open={snackbarObj?.open}
        severity={snackbarObj?.severity}
        message={snackbarObj.message}
      />
      <InformationFAB />
    </Grid>
  );
};

import { Home } from "./Home/Home";
import { Survey } from "./Survey/Survey";
import { SignupPage } from "./Login/Signup";
import { LoginPage } from "./Login/Login";

export const routes = [
  {
    name: "Home",
    element: <Home />,
    path: "/home",
  },
  {
    name: "Survey",
    element: <Survey />,
    path: "/survey",
  },
  {
    name: "Sign Up",
    element: <SignupPage />,
    path: "/signup",
  },
  {
    name: "Log In",
    element: <LoginPage />,
    path: "/",
  },
];

export const baseURL = 'https://psi-pai-webapp.centralindia.cloudapp.azure.com/api/v1'
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Button,
} from "@mui/material";
import "./header.css"; // Import the CSS file
import logo from "../assets/genpact-icon.png";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [userObj, setUserObj] = useState(JSON.parse(localStorage.getItem("user")) || null)
  const navigate = useNavigate();

  const location = useLocation();
  const showAvatar = location.pathname !== "/" || location.pathname !== "/register";

  // const handleUserSwitch = (event) => {
  //   setUserObj(user.filter((row) => row.initial === event.target.value)[0]);
  // };

  const handleLogOut = () => {
    localStorage.setItem("user", null)
    window.dispatchEvent(new Event("storage"));
    setShowUserSelector(!showUserSelector)
    navigate('/')
  }

  useEffect(() => {
    window.addEventListener("storage", () => {
      const userObjFromStorage = JSON.parse(localStorage.getItem("user"));
      setUserObj(userObjFromStorage)
    })
  }, []);

  return (
    <AppBar position="static">
      <Toolbar>
        <span className="title">
          <img src={logo} alt="Logo" className="header-logo" />
        </span>
        {userObj && showAvatar && (
          <div className="avatarContainer">
            <IconButton
              onClick={() => {
                setShowUserSelector(!showUserSelector);
              }}
            >
              <Avatar alt="Avatar">
                {userObj?.initial}
              </Avatar>
            </IconButton>
            {showUserSelector &&
              <Button variant="outlined" onClick={handleLogOut}>Log Out</Button>}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export const getCurrentDateTimeFormatted = () => {
  const now = new Date();

  // Get month, day, hour, and minute with leading zeros if necessary
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  // Combine them into the desired format
  const formattedDateTime = `${month}_${day}_${hours}_${minutes}`;

  return formattedDateTime;
};

import {
  Typography,
  // Autocomplete,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import "./signup.css";
// import { mainDataNew } from "../Home/data";
import { useState } from "react";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { SimpleSnackbar } from "../components/Snackbar";
import { baseURL } from "../routes";

export const SignupPage = () => {
  const [selectedFormData, setSelectedFormData] = useState({
    name: "",
    email_id: "",
    password: "",
    approved: true,
  });
  const [snackbarObj, setSnackbarObj] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate();
  const [connectionActive, setConnectionActive] = useState(false);

  const checkConnection = async () => {
    const res = await axios.get(baseURL);
    if (res?.data?.active) {
      setConnectionActive(true);
    }
  };

  // const tower = [
  //   ...new Set(
  //   mainDataNew.map((row) => {
  //     return row?.tower;
  //   }),
  //   ),
  // ];

  const registerUser = async () => {
    if (connectionActive) {
      if (
        selectedFormData.name.trim() !== "" &&
        selectedFormData.email_id.trim() !== "" &&
        selectedFormData.password.trim() !== ""
      ) {
        const registerUserURL = new URL(baseURL + "/registerUser");

        try {
          const res = await axios.post(registerUserURL, selectedFormData);

          /* Extract initials and load data into localStorage */
          let userData = res?.data;
          if (userData) {
            const initial = userData?.name
              ?.split(" ")
              .map((word) => word[0])
              .join("");
            userData["initial"] = initial;
            if (userData["password"]) {
              delete userData["password"];
            }

            window.localStorage.setItem("user", JSON.stringify(userData));
            window.dispatchEvent(new Event("storage"));
            navigate("/home");
          }
        } catch (e) {
          const errMsg = e?.response?.data?.detail
            ? e?.response?.data?.detail
            : "Please try again later";
          setSnackbarObj({
            open: true,
            message: errMsg,
            severity: "error",
          });
        }
      } else {
        setSnackbarObj({
          open: true,
          message: "All fields are required",
          severity: "error",
        });
      }
    } else {
      setSnackbarObj({
        open: true,
        message: "Unable to connect to the API service",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    checkConnection();
    localStorage.setItem("user", null);
    window.dispatchEvent(new Event("storage"));
  }, []);

  return (
    <div class="main-login-div">
      <Grid container className="container-login-div" gap={2}>
        <Grid item xs={4} className="center">
          <Typography variant="h6" className="login-label">
            {" "}
            Name{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            className="login-input"
            onChange={(event) =>
              setSelectedFormData({
                ...selectedFormData,
                name: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4} className="center">
          <Typography variant="h6" className="login-label">
            {" "}
            User Email{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            className="login-input"
            type="email"
            onChange={(event) =>
              setSelectedFormData({
                ...selectedFormData,
                email_id: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4} className="center">
          <Typography variant="h6" className="login-label">
            {" "}
            Password{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="password"
            size="small"
            className="login-input"
            onChange={(event) =>
              setSelectedFormData({
                ...selectedFormData,
                password: sha256(event.target.value),
              })
            }
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button sx={{ width: "100px" }} onClick={registerUser}>
            {" "}
            Sign Up
          </Button>
        </Grid>
      </Grid>
      <SimpleSnackbar
        snackBarFunction={setSnackbarObj}
        open={snackbarObj?.open}
        severity={snackbarObj?.severity}
        message={snackbarObj.message}
      />
    </div>
  );
};

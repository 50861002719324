import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

export const SimpleSnackbar = ({
  open,
  message = "",
  severity = "success",
  duration = 6000,
  snackBarFunction = () => {},
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    snackBarFunction((prev) => {
      return { ...prev, open: false };
    });
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} action={action}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

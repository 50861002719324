import { Typography, Grid, TextField, Button } from "@mui/material";
import "./signup.css";
import { useEffect, useState } from "react";
import { sha256 } from "js-sha256";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { SimpleSnackbar } from "../components/Snackbar";
import { baseURL } from "../routes";

export const LoginPage = () => {
  const [selectedFormData, setSelectedFormData] = useState({
    email: "",
    password: "",
  });
  const [snackbarObj, setSnackbarObj] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [connectionActive, setConnectionActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const authorized = location?.state?.authorized;

  const checkConnection = async () => {
    const res = await axios.get(baseURL);
    if (res?.data?.active) {
      setConnectionActive(true);
    }
  };

  // const signupUser = async () => {

  // }

  const loginUser = async () => {
    if (connectionActive) {
      if (
        selectedFormData.email.trim() !== "" &&
        selectedFormData.password.trim() !== ""
      ) {
        const getUserURL = new URL(baseURL + "/user");
        const searchParams = new URLSearchParams();
        searchParams.set("email_id", selectedFormData.email);
        searchParams.set("password", selectedFormData.password);
        getUserURL.search = searchParams;

        try {
          const res = await axios.get(getUserURL);

          /* Extract initials and load data into localStorage */
          let userData = res?.data;
          if (userData) {
            const initial = userData?.name
              ?.split(" ")
              .map((word) => word[0])
              .join("");
            userData["initial"] = initial;
            if (userData["password"]) {
              delete userData["password"];
            }

            window.localStorage.setItem("user", JSON.stringify(userData));
            window.dispatchEvent(new Event("storage"));
            navigate("/home");
          }
        } catch (e) {
          const errMsg = e?.response?.data?.detail
            ? e?.response?.data?.detail
            : "Please try again later";
          setSnackbarObj({
            open: true,
            message: errMsg,
            severity: "error",
          });
        }
      } else {
        setSnackbarObj({
          open: true,
          message: "Email and password are required",
          severity: "error",
        });
      }
    } else {
      setSnackbarObj({
        open: true,
        message: "Unable to connect to the API service",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    checkConnection();
    localStorage.setItem("user", null);
    window.dispatchEvent(new Event("storage"));

    if (authorized === "Unauthorized") {
      setSnackbarObj({
        message: "Please login first",
        open: true,
        severity: "error",
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-login-div">
      <Grid container className="container-login-div" gap={2}>
        <Grid item xs={4} className="center">
          <Typography variant="h6" className="login-label">
            {" "}
            User Email{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            size="small"
            className="login-input"
            type="email"
            onChange={(event) =>
              setSelectedFormData({
                ...selectedFormData,
                email: event.target.value,
              })
            }
            placeholder="Enter your email ID..."
          />
        </Grid>
        <Grid item xs={4} className="center">
          <Typography variant="h6" className="login-label">
            Password
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            type="password"
            size="small"
            className="login-input"
            onChange={(event) =>
              setSelectedFormData({
                ...selectedFormData,
                password: sha256(event.target.value),
              })
            }
            placeholder="Enter your password..."
          />
        </Grid>
        <Grid container pl={20}>
          <Grid item xs={6} textAlign="end" ml={-10}>
            <Button
              sx={{ width: "100px" }}
              onClick={() => {
                navigate("/signup", {});
              }}
            >
              {" "}
              SignUp{" "}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="center" ml={-14}>
            <Button sx={{ width: "100px" }} onClick={() => loginUser()}>
              {" "}
              LogIn{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <SimpleSnackbar
        snackBarFunction={setSnackbarObj}
        open={snackbarObj?.open}
        severity={snackbarObj?.severity}
        message={snackbarObj.message}
      />
    </div>
  );
};

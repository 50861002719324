import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Header } from "./Header/Header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRoutes, BrowserRouter } from "react-router-dom";
import { routes } from "./routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#021D44",
    },
    secondary: {
      main: "#06A9D2"
    },
    action: {
      disabledOpacity: 0.1,
      disabledBackground: "#f0f0f0",
      disabled: "#a0a0a0",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "white",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#FF555F",
          '&:hover': {
            backgroundColor: '#06A9D2',
            color: '#fff',
          }
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: "black",
          backgroundColor: "#fff"
        },
      },
    },
  },
});

const AppRoutes = () => {
  return useRoutes(routes);
};

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header />
        <AppRoutes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

export const dropdownData = [
  {
    period: "H1-2023",
  },
  {
    period: "H2-2023",
  },
  {
    period: "H1-2024",
  },
  {
    period: "H2-2024",
  },
  {
    period: "H1-2025",
  },
  {
    period: "H2-2025",
  },
  {
    period: "H1-2026",
  },
  {
    period: "H2-2026",
  },
  {
    period: "H1-2027",
  },
  {
    period: "H2-2027",
  },
  {
    period: "H1-2028",
  },
  {
    period: "H2-2028",
  },
  {
    period: "H1-2029",
  },
  {
    period: "H2-2029",
  },
];

export const cluster = [
  {
    cluster: "MEA",
  },
  {
    cluster: "US",
  },
  {
    cluster: "Western Europe",
  },
  {
    cluster: "East Asia",
  },
  {
    cluster: "MEA",
  },
  {
    cluster: "Canada",
  },
  {
    cluster: "South Asia",
  },
  {
    cluster: "Eastern Europe",
  },
  {
    cluster: "US",
  },
];

export const region = ["MEU", "AMEA", "NA", "LA", "ITS", "RDQ"];

export const user = [
  {
    userName: "shivani.gupta@genpact.com",
    name: "Shivani Gupta",
    initial: "SG",
    progress: 63,
    questionCount: "9 out of 17",
  },
  {
    userName: "pradeep.sharma@genpact.com",
    name: "Pradeep Sharma",
    initial: "PS",
    progress: 50,
    questionCount: "8 out of 17",
  },
  {
    userName: "harshita.agarwal@genpact.com",
    name: "Harshita Agarwal",
    initial: "HA",
    progress: 24,
    questionCount: "4 out of 17",
  },
];

export const ratings = [
  {
    rating: 1,
  },
  {
    rating: 2,
  },
  {
    rating: 3,
  },
  {
    rating: 4,
  },
  {
    rating: "N/A",
  },
];

export const questions = {
  Q1: {
    subProcess: "Journals",
    question: "Is the activity in scope across all countries?",
    questionCategory: "Standardization",
  },
  Q2: {
    subProcess: "Journals",
    question: "How are inputs received?",
    questionCategory: "Standardization",
  },
  Q3: {
    subProcess: "Journals",
    question:
      "Are the calculations and analysis performed for creating journals consistent?",
    questionCategory: "Standardization",
  },
  Q4: {
    subProcess: "Journals",
    question:
      "Is the split of journals posted (GLSU, self triggered and inputs received from client) similar? What is the split between GLSU, Self triggered and inputs received from client (Please ans in Free text)?",
    questionCategory: "Standardization",
  },
  Q5: {
    subProcess: "Journals",
    question: "Are the JE prepared in a standard manner?",
    questionCategory: "Standardization",
  },
  Q6: {
    subProcess: "Journals",
    question: "How are JE parked?/How are JE sent to other team?",
    questionCategory: "Standardization",
  },
  Q7: {
    subProcess: "Journals",
    question:
      "How much is data extraction for manual journals automated? Which tool is been deployed for the process? (Please ans in Free text)?",
    questionCategory: "Automation",
  },
  Q8: {
    subProcess: "Journals",
    question:
      "What is the degree of automation of workings? Which tool is been deployed for the process? (Please ans in Free text)?",
    questionCategory: "Automation",
  },
  Q9: {
    subProcess: "Journals",
    question:
      "Are there direct posting, manual park and post, accelerator or How is JE shared to other team? Which tool is been deployed for the process? (Please ans in Free text)?",
    questionCategory: "Automation",
  },

  Q10: {
    subProcess: "MEC and Reporting",
    question: "Is the activity in scope across all countries?",
    questionCategory: "Standardization",
  },
  Q11: {
    subProcess: "MEC and Reporting",
    question: "How are inputs received?",
    questionCategory: "Standardization",
  },
  Q12: {
    subProcess: "MEC and Reporting",
    question:
      "Are the calculations performed and analysis for performing the activities/extracting the report standard?",
    questionCategory: "Standardization",
  },
  Q13: {
    subProcess: "MEC and Reporting",
    question:
      "Are the same apps/tools used for performing the activity?What are the tools used for reporting and transactional activities? List down the same in comments?",
    questionCategory: "Standardization",
  },
  Q14: {
    subProcess: "MEC and Reporting",
    question: "Are risk and controlls standard across ?",
    questionCategory: "Standardization",
  },
  Q15: {
    subProcess: "MEC and Reporting",
    question: "Is the output format standard?",
    questionCategory: "Standardization",
  },
  Q16: {
    subProcess: "MEC and Reporting",
    question:
      "How much is data extraction for manual journals automated? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q17: {
    subProcess: "MEC and Reporting",
    question:
      "What is the degree of automation of workings? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q18: {
    subProcess: "MEC and Reporting",
    question:
      "What is the degree of automation of output flow? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q19: {
    subProcess: "Master Data",
    question: "How are inputs received?",
    questionCategory: "Standardization",
  },
  Q20: {
    subProcess: "Master Data",
    question: "How is the input format?",
    questionCategory: "Standardization",
  },
  Q21: {
    subProcess: "Master Data",
    question:
      "Are the same apps/tools used for performing the activity?What are the tools used for reporting and transactional activities? List down the same in comments",
    questionCategory: "Standardization",
  },
  Q22: {
    subProcess: "Master Data",
    question: "Is the output format standard ?",
    questionCategory: "Standardization",
  },
  Q23: {
    subProcess: "Master Data",
    question: "Is the frequency of the activity consistent?",
    questionCategory: "Standardization",
  },
  Q24: {
    subProcess: "Master Data",
    question: "Are risk and controlls standard across ?",
    questionCategory: "Standardization",
  },
  Q25: {
    subProcess: "Master Data",
    question:
      "How much is data extraction for manual journals automated? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q26: {
    subProcess: "Master Data",
    question:
      "What is the degree of automation of workings? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q27: {
    subProcess: "Master Data",
    question:
      "What is the degree of automation of output flow? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q28: {
    subProcess: "Budget and Forecast",
    question: "Is the activity in scope across all countries?",
    questionCategory: "Standardization",
  },
  Q29: {
    subProcess: "Budget and Forecast",
    question: "How are inputs received?",
    questionCategory: "Standardization",
  },
  Q30: {
    subProcess: "Budget and Forecast",
    question: "Is the frequency of performing the activity consistent?",
    questionCategory: "Standardization",
  },
  Q31: {
    subProcess: "Budget and Forecast",
    question: "What is the basis of computation?",
    questionCategory: "Standardization",
  },
  Q32: {
    subProcess: "Budget and Forecast",
    question:
      "Is the template for receiving inputs consistent across the region?",
    questionCategory: "Standardization",
  },
  Q33: {
    subProcess: "Budget and Forecast",
    question:
      "Is the workings and calculation method to determine the final value standard?",
    questionCategory: "Standardization",
  },
  Q34: {
    subProcess: "Budget and Forecast",
    question: "Is the mode of submission common?",
    questionCategory: "Standardization",
  },
  Q35: {
    subProcess: "Budget and Forecast",
    question:
      "How much is data extraction for manual journals automated? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q36: {
    subProcess: "Budget and Forecast",
    question:
      "What is the degree of automation of workings? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q37: {
    subProcess: "Budget and Forecast",
    question:
      "What is the degree of automation of output flow? Which tool is been deployed for the process? (Please ans in Free text)",
    questionCategory: "Automation",
  },
  Q38: {
    subProcess: "MEC",
    question:
      "Inputs: a. Source of Inputs (Teams/System) b. Mode (email/sytem extraction/website download) c. Form (pdf/excel/email body/word/html/scanned images)",
    questionCategory: "Standardization",
  },
  Q39: {
    subProcess: "MEC",
    question: "Process/ Workings/ Calculations/ Analysis/ Validations",
    questionCategory: "Standardization",
  },
  Q40: {
    subProcess: "MEC",
    question: "Varied tools in use",
    questionCategory: "Standardization",
  },
  Q41: {
    subProcess: "MEC",
    question: "Controls/ Approval matrix",
    questionCategory: "Standardization",
  },
  Q42: {
    subProcess: "MEC",
    question:
      "Output: a. Mode (e.g. system update ) b. Form (excel, pdf, email, txt.)",
    questionCategory: "Standardization",
  },
  Q43: {
    subProcess: "MEC",
    question:
      "Data/Inputs extraction/collation: a. Manual extraction b. Extraction through macro/ variants/ smartview refresh",
    questionCategory: "Standardization",
  },
  Q44: {
    subProcess: "MEC",
    question:
      "Workings, calculations, analysis (if applicable) a. RPA/ BOT b. Automated business rules validations",
    questionCategory: "Standardization",
  },
  Q45: {
    subProcess: "MEC",
    question: "Output: a. Automated data flow b. AI/ NLP based output",
    questionCategory: "Standardization",
  },
  Q46: {
    subProcess: "Journal",
    question:
      "a. Source of Inputs (Teams/System) b. Mode (email/sytem extraction/website download) c. Form (pdf/excel/email body/word/html/scanned images)",
    questionCategory: "Standardization",
  },
  Q47: {
    subProcess: "Journal",
    question:
      "Ways of working - JE Preparation, Business rules & validations, Manual/AI Accelerator (GLSU preparation) (Scoring)",
    questionCategory: "Standardization",
  },
  Q48: {
    subProcess: "Journal",
    question: "JE workings, calculations, analysis (if applicable)",
    questionCategory: "Standardization",
  },
  Q49: {
    subProcess: "Journal",
    question:
      "JE park variations: a. Manual park of journals b. AI - JE Park robot c. Direct postings (no park - recurring)",
    questionCategory: "Standardization",
  },
  Q50: {
    subProcess: "Journal",
    question:
      "Data/Inputs extraction/collation: a. Manul extraction b. Extraction through macro/SAP scripting or variants c. AI/NLP based inputs collation",
    questionCategory: "Standardization",
  },
  Q51: {
    subProcess: "Journal",
    question:
      "JE workings, calculations, analysis (if applicable) a. RPA/BOT/CORA AI b. Automated business rules validations",
    questionCategory: "Standardization",
  },
  Q52: {
    subProcess: "Journal",
    question:
      "JE park/post variations: a. Manual park & post b. Direct postings c. Accelerator - to check",
    questionCategory: "Standardization",
  },
  Q53: {
    subProcess: "Account Reconciliations",
    question: "Grouping of accounts",
    questionCategory: "Standardization",
  },
  Q54: {
    subProcess: "Account Reconciliations",
    question: "Level of reconciliation - Summary/Aggregate",
    questionCategory: "Standardization",
  },
  Q55: {
    subProcess: "Account Reconciliations",
    question:
      "Template utilisation: a. BL template (account specific / general list) b. Manual template upload ",
    questionCategory: "Standardization",
  },
  Q56: {
    subProcess: "Account Reconciliations",
    question: "SL to GL reconciliation (if applicable)",
    questionCategory: "Standardization",
  },
  Q57: {
    subProcess: "Account Reconciliations",
    question: "Grouping of accounts",
    questionCategory: "Standardization",
  },
  Q58: {
    subProcess: "Account Reconciliations",
    question: "Variance Threshold",
    questionCategory: "Standardization",
  },
  Q59: {
    subProcess: "Account Reconciliations",
    question: "Utilisation of auto certification in BL",
    questionCategory: "Standardization",
  },
  Q60: {
    subProcess: "Account Reconciliations",
    question:
      "Recon preparation (Template rollover, workings, supportings, LRT classification,   BL upload, open item analysis, follow up)",
    questionCategory: "Standardization",
  },
  Q61: {
    subProcess: "Account Reconciliations",
    question:
      "Recon approval and Governance (Recon review, rework, approval and reporting/Governance)",
    questionCategory: "Standardization",
  },
};

// New data - 06/17/2024
export const mainDataNew = [
  {
    tower: "OVH",
    process: "FPNA",
    subProcess: "Journals",
  },
  {
    tower: "OVH",
    process: "FPNA",
    subProcess: "MEC & Reporting",
  },
  {
    tower: "OVH",
    process: "FPNA",
    subProcess: "Master Data",
  },
  {
    tower: "OVH",
    process: "FPNA",
    subProcess: "Budget and Forecast",
  },
  {
    tower: "AER",
    process: "USGAAP",
    subProcess: "MEC",
  },
  {
    tower: "AER",
    process: "USGAAP",
    subProcess: "Journal",
  },
  {
    tower: "AER",
    process: "USGAAP",
    subProcess: "Account Reconciliations",
  },
  {
    tower: "ISC",
    process: "CS&L",
    subProcess: "Journals",
  },
  {
    tower: "ISC",
    process: "CS&L",
    subProcess: "Transactional/Reporting",
  },
  {
    tower: "ISC",
    process: "CS&L",
    subProcess: "Account Reconciliations",
  },
  {
    tower: "ISC",
    process: "CS&L",
    subProcess: "Budget and Forecasting",
  },
];

export const subProcessCategory = [
  // FPNA
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "AMS Accruals ",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "BPO Accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "CTO accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Germany Data Center Accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "MBS Accruals (GB50)",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Pacifier JE Accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare 3rd party merchandiser accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Audit accruals",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Company Vehicle accrual(Feul and incidentals)",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Employee benefits accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Legal accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare MIP accruals",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Postage and courier accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Review/Prepare Project accruals (contractor and counsultant)",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "Security Accrual",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "SIF Accruals ",
  },
  {
    subProcess: "Journals",
    category: "Accruals",
    subCategory: "WBS Accruals",
  },
  // Reclass
  {
    subProcess: "Journals",
    category: "Reclass",
    subCategory: "prepare Incorrect GL reclass (driven by PO or Concur)",
  },
  {
    subProcess: "Journals",
    category: "Reclass",
    subCategory: "prepare Marketing sample recalss to A&C",
  },
  {
    subProcess: "Journals",
    category: "Reclass",
    subCategory: "prepare Overhead to FME reclass",
  },
  {
    subProcess: "Journals",
    category: "Reclass",
    subCategory: "prepare Payroll reclass for incorrect CC or GL",
  },
  {
    subProcess: "Journals",
    category: "Reclass",
    subCategory:
      "prepare Reclass of any incorrect GL or CC… reclass categories",
  },

  // Intercompany
  {
    subProcess: "Journals",
    category: "Intercompany",
    subCategory: "Review Cross charges expat",
  },
  {
    subProcess: "Journals",
    category: "Intercompany",
    subCategory: "Review Cross charges non expat",
  },
  //Amortization
  {
    subProcess: "Journals",
    category: "Amortization",
    subCategory: "Review/Prepare Insurance, membership and rental amortization",
  },
  //Analysis
  {
    subProcess: "Journals",
    category: "Analysis",
    subCategory: "Review of Missing GR",
  },
  // MEC Subprocess
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "AMS Report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "App Enhancement Report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "IBS RPO Report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "MBS Comparison Report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "MBS People Service Analysis Report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "MDS People Dashboard Reporting ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Opex WBS Report (AC vs ACT)",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Perform Flash/pre close template reporting ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Adaptive headcount report ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare C&B report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare CG&A report Includng SAP",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Company vehicle tracker",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Financial service report including SAP details",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare FRS report with SAP data",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare HUB reporting",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Legal report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Overhead report with comment for all packages",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare People Cost Report with HC data",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Sales overhead report",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Travel report including SA",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "Prepare Vacancy tracker",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "WBS input for Power BI tool",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting",
    subCategory: "WBS Report for MSPO",
  },

  // Reporting - Analysis
  {
    subProcess: "MEC and Reporting",
    category: "Reporting Analysis",
    subCategory: "review of trend analysis for people and non people packages",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting Analysis",
    subCategory:
      "review of phasing through budget line items in non people packages",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Reporting Analysis",
    subCategory: "Prepare Driver analysis and commentary",
  },
  // Non MEC Analysis
  {
    subProcess: "MEC and Reporting",
    category: "Non MEC Analysis",
    subCategory: "Review Employee validation",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Non MEC Analysis",
    subCategory: "Rolling forecast update",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Non MEC Analysis",
    subCategory: "intercompany cross charge",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Non MEC Analysis",
    subCategory: "PO analysis- open PO report ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Non MEC Analysis",
    subCategory: "Rolling forecast update",
  },
  // Month end close - Analysis
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - Analysis",
    subCategory: "Review Headcount ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - Analysis",
    subCategory: "SAP data extraction ",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - Analysis",
    subCategory: "Validation payroll cost",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - Analysis",
    subCategory: "Analyse Local MIP /Local SIP",
  },
  // Month end close - System Tie Out
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - System Tie Out",
    subCategory: "Manual input in CMT (e.g. oh to fme etc)",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - System Tie Out",
    subCategory: "Perform CMT vs FIT reconcilliation",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - System Tie Out",
    subCategory: "Perform CMT vs Tableau reconcilation",
  },
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - System Tie Out",
    subCategory: "Perform Perform SAP vs CMT reconcilliation",
  },
  // Month end close - Intercompany
  {
    subProcess: "MEC and Reporting",
    category: "Month end close - Intercompany",
    subCategory:
      "Review Global rebill cross charge analysis-Cash and Non-Cash Charges ",
  },
  // Master data sub process
  {
    subProcess: "Master Data",
    category: "AC/OP",
    subCategory: "Identify the number of planner involved in CMT",
  },
  {
    subProcess: "Master Data",
    category: "AC/OP",
    subCategory: "Provide CMT training & required material to all planners",
  },
  {
    subProcess: "Master Data",
    category: "AC/OP",
    subCategory: "Provide system support to the planner for planning",
  },
  {
    subProcess: "Master Data",
    category: "AC/OP",
    subCategory: "Raise system access for planners",
  },
  {
    subProcess: "Master Data",
    category: "Cycle Updation",
    subCategory: "Cycle bespoke updation or creation of CC",
  },
  {
    subProcess: "Master Data",
    category: "System Validation",
    subCategory: "Taleau validation in month end",
  },
  //CC creation request approver
  {
    subProcess: "Master Data",
    category: "CC creation request approver",
    subCategory: "Approve cost centre creation/change request -SAP",
  },
  // System training
  {
    subProcess: "Master Data",
    category: "System training",
    subCategory: "CMT training to planners",
  },
  // CC creation/Modification
  {
    subProcess: "Master Data",
    category: "CC creation/Modification",
    subCategory: "Create cost centre - SAP",
  },
  // GL and CC Mapping
  {
    subProcess: "Master Data",
    category: "GL and CC Mapping",
    subCategory: "Create mapping files for SAP - CMT mapping",
  },
  // system access
  {
    subProcess: "Master Data",
    category: "system access",
    subCategory: "Provide CMT access to planners",
  },
  // Budget & Forecast
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Perform final FIT vs CMT tie out",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Perform Take to Make in CMT",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Receive changes for forecast from BU controller",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Send Final budget numbers to the stakeholders",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Send reports at BU level after CMT input to controllers",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Update changes in CMT based on the input received from BU",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Upload FIT once CMT is finalized",
  },
  {
    subProcess: "Budget and Forecast",
    category: "Sept Forecast",
    subCategory: "Validate tableau after forecast upload",
  },
  // AC/OP - Budget and Forecast
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Upload FIT once CMT is finalized",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Share AC vs PY report to controller",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory:
      "Send reports at Region/BU/Function level after CMT/Adaptive input",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory:
      "Receive changes for Non people changes from BU controller or function owner",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Receive changes for headcount from HR or function owners",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Perform FIT vs CMT tie out",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "perform CMT vs adaptive reconcilliation",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Non people planning in CMT",
  },
  {
    subProcess: "Budget and Forecast",
    category: "AC/OP",
    subCategory: "Headcount planning in Adaptive",
  },
  // USGAAP
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - A&C ",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Audit Fee ",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Business Accrual",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Car Insurance / Lease",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Commission ",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Intercompany",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Insurance",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Interest (Derivatives/Hedging) ",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - MBS,ITS,CTO,AMS",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Rentals",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Repair and Maintenance",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Accruals - Royalty",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Bad debt provision",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "Utility provision (Electricity, Natural Gas, Water)",
  },
  {
    subProcess: "Journal",
    category: "Accruals & Provisions",
    subCategory: "BS/Accrual release",
  },
  // Cash & Bank Journal
  {
    subProcess: "Journal",
    category: "Cash & Bank Journals",
    subCategory: "Bank journals (Interest, Charges etc.)",
  },
  {
    subProcess: "Journal",
    category: "Cash & Bank Journals",
    subCategory: "Cash/Bank receipts",
  },
  {
    subProcess: "Journal",
    category: "Cash & Bank Journals",
    subCategory: "Fund Transfers",
  },
  {
    subProcess: "Journal",
    category: "Cash & Bank Journals",
    subCategory: "Petty cash",
  },
  // Clearing & Reclass Journals
  {
    subProcess: "Journal",
    category: "Clearing & Reclass Journals",
    subCategory: "Write Off",
  },
  {
    subProcess: "Journal",
    category: "Clearing & Reclass Journals",
    subCategory: "GL/CC clearing",
  },
  {
    subProcess: "Journal",
    category: "Clearing & Reclass Journals",
    subCategory: "BS/GL/CC reclass (including HFM reclass)",
  },
  {
    subProcess: "Journal",
    category: "Clearing & Reclass Journals",
    subCategory: "LT to/from ST reclass",
  },
  // Forex
  {
    subProcess: "Journal",
    category: "Forex",
    subCategory: "Forex clearing",
  },
  {
    subProcess: "Journal",
    category: "Forex",
    subCategory: "Forex reclass",
  },
  //Intercompany X Charge
  {
    subProcess: "Journal",
    category: "Intercompany X Charge",
    subCategory: "Central A&C Reallocation",
  },
  {
    subProcess: "Journal",
    category: "Intercompany X Charge",
    subCategory: "Dr LAN Accrual",
  },
  {
    subProcess: "Journal",
    category: "Intercompany X Charge",
    subCategory: "Mark up Correction",
  },
  {
    subProcess: "Journal",
    category: "Intercompany X Charge",
    subCategory: "Mark up Posting",
  },
  {
    subProcess: "Journal",
    category: "Intercompany X Charge",
    subCategory: "MR to OPCO transfer",
  },
  // Payroll & Pension
  {
    subProcess: "Journal",
    category: "Payroll & Pension",
    subCategory: "Payroll journals",
  },
  {
    subProcess: "Journal",
    category: "Payroll & Pension",
    subCategory: "Payroll accruals/adjustments",
  },
  {
    subProcess: "Journal",
    category: "Payroll & Pension",
    subCategory: "Pension journals",
  },
  {
    subProcess: "Journal",
    category: "Payroll & Pension",
    subCategory: "Accruals - MIP & SIP",
  },
  {
    subProcess: "Journal",
    category: "Payroll & Pension",
    subCategory: "Accruals - Holiday, Meal & Vacation",
  },
  // Prepayment journals
  {
    subProcess: "Journal",
    category: "Prepayment journals",
    subCategory: "Prepaid/Prepayment journals & Amortisation",
  },
  // Restructuring
  {
    subProcess: "Journal",
    category: "Restructuring",
    subCategory: "Restructuring",
  },
  // Statistical & Statutory
  {
    subProcess: "Journal",
    category: "Statistical & Statutory",
    subCategory: "Statistical transfer journals",
  },
  {
    subProcess: "Journal",
    category: "Statistical & Statutory",
    subCategory: "Statutory journals",
  },
  // Taxation
  {
    subProcess: "Journal",
    category: "Taxation",
    subCategory: "Tax provision journals",
  },
  {
    subProcess: "Journal",
    category: "Taxation",
    subCategory: "Tax adjustment/reclass journals",
  },
  // Account Reconciliations
  {
    subProcess: "Account Reconciliations",
    category: "Account Receivables",
    subCategory: "Account Receivables",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Accounts Payable",
    subCategory: "Accounts Payable",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Accrued Liabilities",
    subCategory: "Accrued Liabilities",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Cash & Banking",
    subCategory: "Cash & Banking",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Customer Deduction",
    subCategory: "Customer Deduction",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Deferred Income & Charges",
    subCategory: "Deferred Income & Charges",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Equity&Dividend",
    subCategory: "Equity&Dividend",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Fixed Asset",
    subCategory: "Fixed Asset",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Forex Accounts",
    subCategory: "Forex Accounts",
  },
  {
    subProcess: "Account Reconciliations",
    category: "GRIR",
    subCategory: "GRIR",
  },
  {
    subProcess: "Account Reconciliations",
    category: "IC Payable & Receivable",
    subCategory: "IC Payable & Receivable",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Intercompany Loan",
    subCategory: "Intercompany Loan",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Investment",
    subCategory: "Investment",
  },
  {
    subProcess: "Account Reconciliations",
    category: "LT  Assets",
    subCategory: "LT  Assets",
  },
  {
    subProcess: "Account Reconciliations",
    category: "LT ST Debts",
    subCategory: "LT ST Debts",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Other 3rd Party Receivable",
    subCategory: "Other 3rd Party Receivable",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Payroll",
    subCategory: "Payroll",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Pension",
    subCategory: "Pension",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Prepaid Expenses",
    subCategory: "Prepaid Expenses",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Taxes",
    subCategory: "Taxes",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Unallocated cash",
    subCategory: "Unallocated cash",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Vendor Advances",
    subCategory: "Vendor Advances",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Inventory",
    subCategory: "Inventory",
  },
  {
    subProcess: "Account Reconciliations",
    category: "Lease Accounts",
    subCategory: "Lease Accounts",
  },
  // MEC
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "HFM submission for AOI",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "HFM submission for BOI & Balance sheet",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "Q Account & lease P&L number upload in HFM",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "Intercompany submission",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "SAP - HFM Reconciliations",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "Tax Booking",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "Reporting package",
  },
  {
    subProcess: "MEC",
    category: "HFM Reporting",
    subCategory: "CON Reporting",
  },
  {
    subProcess: "MEC",
    category: "Validation checks including daily checks & others",
    subCategory: "Daily Close Checks",
  },
  {
    subProcess: "MEC",
    category: "Validation checks including daily checks & others",
    subCategory: "AOI/BOI Check",
  },
  {
    subProcess: "MEC",
    category: "Validation checks including daily checks & others",
    subCategory: "P&L Balance check for entities other than Sales entity",
  },
  {
    subProcess: "MEC",
    category: "Validation checks including daily checks & others",
    subCategory: "BS Trend Analysis",
  },
  {
    subProcess: "MEC",
    category: "Period Management",
    subCategory: "Opening of new period ranges",
  },
  {
    subProcess: "MEC",
    category: "Period Management",
    subCategory: "Closing of current month period ranges",
  },
  {
    subProcess: "MEC",
    category: "Period Management",
    subCategory: "MM Module period management (MMPV/MMRV/ML)",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Monthly pension number recon",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "PMMR rate recon before final FX revaluation",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Recurring check",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "New CC",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "New Cost Element",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "ZL Document check",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Revenue Accounting & Cut off",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Return & Refusals",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Volume Adjustment",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Net Revenue Recon",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Opal",
  },
  {
    subProcess: "MEC",
    category: "Data reconciliation (Sales, Volume, Pension & Others)",
    subCategory: "Volume",
  },
  {
    subProcess: "MEC",
    category: "Assessment cycles",
    subCategory: "CO Cycles / Assessment Cycles",
  },
  {
    subProcess: "MEC",
    category: "Assessment cycles",
    subCategory: "COPA Cycles",
  },
  {
    subProcess: "MEC",
    category: "Assessment cycles",
    subCategory: "Distribution Cycle (OPCO)",
  },
  {
    subProcess: "MEC",
    category: "Forex revaluation",
    subCategory: "Monthly FX revaluation",
  },
  {
    subProcess: "MEC",
    category: "Batch job runs including control checks",
    subCategory: "Payroll processing",
  },
  {
    subProcess: "MEC",
    category: "Batch job runs including control checks",
    subCategory: "Recurring job run",
  },
  {
    subProcess: "MEC",
    category: "Clearing (AP, Payroll, others)",
    subCategory: "Payroll clearing",
  },
  {
    subProcess: "MEC",
    category: "Clearing (AP, Payroll, others)",
    subCategory: "BS GL clearings",
  },
  {
    subProcess: "MEC",
    category: "Clearing (AP, Payroll, others)",
    subCategory: "Trade Deal GL clearings",
  },
  {
    subProcess: "MEC",
    category: "Clearing (AP, Payroll, others)",
    subCategory: "Forex clearing",
  },
  {
    subProcess: "MEC",
    category: "System recon (FI, COPA, OPAL, FIT)",
    subCategory: "FI-COPA Recon, COPA-OPAL Recon, COPA-FIT recon finalization",
  },
  {
    subProcess: "MEC",
    category: "System recon (FI, COPA, OPAL, FIT)",
    subCategory: "COPA-BI Recon",
  },
  {
    subProcess: "MEC",
    category: "System recon (FI, COPA, OPAL, FIT)",
    subCategory: "IC- TP Recon between Sales entity & OPCO",
  },
  {
    subProcess: "MEC",
    category: "Intercompany Recharges (Auto & Manual) ",
    subCategory: "Bespoke invoicing in Procurement/Service /OPCO/R&DQ",
  },
  {
    subProcess: "MEC",
    category: "Intercompany Recharges (Auto & Manual) ",
    subCategory: "Tolling Recharges",
  },
  {
    subProcess: "MEC",
    category: "Intercompany Recharges (Auto & Manual) ",
    subCategory: "A&C Recharges",
  },
  {
    subProcess: "MEC",
    category: "Intercompany Recharges (Auto & Manual) ",
    subCategory: "ICWF Accruals",
  },
  {
    subProcess: "MEC",
    category: "MDM Activities",
    subCategory: "WBS Reclass & Settlement",
  },
  {
    subProcess: "MEC",
    category: "MDM Activities",
    subCategory: "WBS creation",
  },
  {
    subProcess: "MEC",
    category: "MDM Activities",
    subCategory: "Cycle & Bespoke changes",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "Non-Operating Projects Reporting",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "OIE Reporting & Segregation",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "Project segregation report - ZBB Accrual (SAP Report)",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "Project segregation report - ZBB Accrual (KS13)",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "Project segregation report - ZBB Accrual (YRTR_3129)",
  },
  {
    subProcess: "MEC",
    category: "Flash Reporting",
    subCategory: "P&L Publishing",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "Bad Record",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "FIT Submission AnC",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "FIT Submission GM & ICP",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "FIT Submission OVH & OIE",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "New SKU Validation",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "Periodic Valuation",
  },
  {
    subProcess: "MEC",
    category: "FIT Submission",
    subCategory: "Win shuttle COPA Adjustment - COGS/T&W/A&C",
  },
];

export const questionScoring = [
  {
    id: 1,
    ScoringOfStandardization: "Standard ways of working across all regions",
    Score: 4,
    ScoringOfAutomation: "Automation between 76-100%",
    ScoreAutomation: 4,
    ColorCode: "Blue For Standardization",
  },
  {
    id: 2,
    ScoringOfStandardization:
      "Standard ways of working within the region but varies across regions",
    Score: 3,
    ScoringOfAutomation: "Automation between 51-75%",
    ScoreAutomation: 3,
    ColorCode: "Red for Automation",
  },
  {
    id: 3,
    ScoringOfStandardization:
      "Standard ways of working within the clusters/regions but varies for the region",
    Score: 2,
    ScoringOfAutomation: "Automation between 26-50%",
    ScoreAutomation: 2,
  },
  {
    id: 4,
    ScoringOfStandardization:
      "Diverse / incosistent ways of working across countries within the same region",
    Score: 1,
    ScoringOfAutomation: "Automation between 0-25%",
    ScoreAutomation: 1,
  },
];

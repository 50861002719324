import * as React from "react";
import Popover from "@mui/material/Popover";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { Grid, Button, Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { questionScoring } from "../Home/data";

export const BasicPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    {
      field: "ScoringOfStandardization",
      headerName: "Scoring Of Standardization",
      minWidth: 550,
    },
    { field: "Score", minWidth: 50 },
    {
      field: "ScoringOfAutomation",
      headerName: "Scoring Of Automation",
      minWidth: 225,
    },
    { field: "ScoreAutomation", headerName: "Score Automation" },
    { field: "ColorCode", headerName: "Color Code" },
  ];

  return (
    <div>
      <Grid
        item
        // py={1}
        mt={1}
        gap={1}
        className="pop-icon"
        padding={1}
      >
        
          <Typography
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            mt={1.2}
            sx={{color: "#000"}}
          >
            Score Legends
          </Typography>
          <Button variant="outlined" onClick={handleClick} size="small">
            <IconButton size="small">
              <LegendToggleIcon fontSize="small"/>
            </IconButton>
          </Button>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ height: 400, width: 1000 }}>
          <DataGrid
            columns={columns}
            rows={questionScoring}
            hideFooter
            showCellVerticalBorder
          />
        </Box>
      </Popover>
    </div>
  );
};

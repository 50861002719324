import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { dropdownData } from "./data";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getClusterValues,
  getProcessValues,
  getRegionValues,
  getSubProcessValues,
} from "./utils";
import "./home.css";
import { SimpleSnackbar } from "../components/Snackbar";
import axios from "axios";
import { baseURL } from "../routes";

import * as React from "react";
import { InformationFAB } from "../components/InformationFAB";

export const Home = () => {
  const [selectedFormData, setSelectedFormData] = useState({
    tower: "",
    process: "",
    subProcess: "",
    region: "",
    cluster: "",
    period: "",
    inputDataType: "",
  });
  // const [showDataPreview, setShowDataPreview] = useState(false);
  // eslint-disable-next-line
  const [userEmail, setUserEmail] = useState(null);
  const [userMappingData, setUserMappingData] = useState([]);
  const [formElements, setFormElements] = useState([
    {
      id: "tower",
      name: "Tower",
      data: ["No Tower Found"],
    },
    {
      id: "process",
      name: "Process",
      data: ["No Process Found"],
      disabled: true,
    },
    {
      id: "subProcess",
      name: "Sub Process",
      data: ["No Sub Process Found"],
      disabled: true,
    },
    {
      id: "region",
      name: "Region",
      data: [],
      disabled: true,
    },
    {
      id: "cluster",
      name: "Cluster",
      data: [],
      disabled: true,
    },
    {
      id: "period",
      name: "Period",
      data: dropdownData.map((row) => {
        return row?.period;
      }),
    },
    {
      id: "inputDataType",
      name: "Input Data Type",
      data: ["As Is", "To Be"],
    },
  ]);
  const [snackbarObj, setSnackbarObj] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate();

  const isFormIncomplete = Object.values(selectedFormData).some(
    (value) => value === "" || !value
  );

  useEffect(() => {
    if (selectedFormData.tower !== "") {
      const currentFormElements = formElements.map((elem) => {
        if (elem?.id === "process") {
          const processList = [
            ...new Set(getProcessValues(selectedFormData, userMappingData)),
          ];
          elem.data =
            processList.length > 0 ? processList : ["No Process Found"];
          elem.disabled = processList.length === 0;
        }

        if (elem?.id === "subProcess") {
          const subProcessList = [
            ...new Set(getSubProcessValues(selectedFormData, userMappingData)),
          ];
          elem.data =
            subProcessList.length > 0
              ? subProcessList
              : ["No Sub Process Found"];
          elem.disabled = subProcessList.length === 0;
        }

        if (elem?.id === "region") {
          const regions = [
            ...new Set(getRegionValues(selectedFormData, userMappingData)),
          ];
          elem.data = regions.length > 0 ? regions : ["No Regions Found"];
          elem.disabled = regions.length === 0;
        }

        if (elem?.id === "cluster") {
          const clusters = [
            ...new Set(getClusterValues(selectedFormData, userMappingData)),
          ];
          elem.data = clusters.length > 0 ? clusters : ["No Clusters Found"];
          elem.disabled = clusters.length === 0;
        }

        return elem;
      });
      setFormElements(currentFormElements);
    }

    // eslint-disable-next-line
  }, [
    selectedFormData.tower,
    selectedFormData.process,
    selectedFormData.subProcess,
    selectedFormData.region,
  ]);

  const getUserMapping = async (userEmailFromAPI) => {
    console.log(userEmailFromAPI);
    if (userEmailFromAPI !== "") {
      try {
        const getUserMappingsURL = new URL(baseURL + "/userMapping");
        const searchParams = new URLSearchParams();
        searchParams.set("email_id", userEmailFromAPI);
        getUserMappingsURL.search = searchParams;
        const res = await axios.get(getUserMappingsURL);
        if (res.status === 200) {
          const userMappingDataFromAPI = res?.data;
          setUserMappingData(userMappingDataFromAPI);

          // Extract Tower value
          const newFormElements = formElements.map((elem) => {
            if (elem.id === "tower") {
              elem.data = [
                ...new Set(
                  userMappingDataFromAPI.map((userMap) => userMap.tower)
                ),
              ];
            }
            return elem;
          });
          setFormElements(newFormElements);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setSnackbarObj({
        open: true,
        message:
          "Your email ID is not mapped correctly, please contact admin for help",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/", { state: { authorized: "Unauthorized" } });
    } else {
      setUserEmail(user?.email_id);
      getUserMapping(user?.email_id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className="main-grid">
        <Typography variant="h4" mt={2}>
          STANDARDIZATION & AUTOMATION INDEX MEASURE
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item className="filter-container">
            <Grid container gap={2}>
              {formElements &&
                formElements?.map((elem) => {
                  return (
                    <Grid item xs={3.5}>
                      <Typography variant="h6">{elem?.name}</Typography>

                      <Autocomplete
                        disablePortal
                        disabled={elem?.disabled}
                        size="small"
                        id="combo-box-demo"
                        options={elem?.data}
                        value={selectedFormData[elem?.id]}
                        onChange={(event, newValue) => {
                          setSelectedFormData((prevState) => ({
                            ...prevState,
                            [elem.id]: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Box className="flex-grow">
              <Grid container mt={4} gap={2}>
                <Grid item xs={5.6} container justifyContent="flex-end">
                  {/* <Button
                    variant="contained"
                    onClick={() => {
                      setShowDataPreview(!showDataPreview);
                    }}
                    sx={{
                      width: "60%",
                      justifySelf: "right",
                      alignSelf: "right",
                    }}
                  >
                    Data Preview
                  </Button> */}
                </Grid>
                <Grid item xs={5.6}>
                  <Button
                    variant="contained"
                    disabled={isFormIncomplete}
                    onClick={() => {
                      localStorage.setItem(
                        "entryPageFormData",
                        selectedFormData
                      );
                      navigate("/survey", {
                        state: { formData: selectedFormData },
                      });
                    }}
                    sx={{ width: "60%" }}
                  >
                    Let's Start
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <SimpleSnackbar
        snackBarFunction={setSnackbarObj}
        open={snackbarObj?.open}
        severity={snackbarObj?.severity}
        message={snackbarObj.message}
      />
      <InformationFAB />
    </Grid>
  );
};

import * as React from "react";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const classes = {
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 20,
        textAlign: "center",
        color: "blue",
        fontFamily: "Roboto",
    },
};

export const InformationPage = () => {
    return (
        <div style={{ padding: '6px', ...classes.root }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h2> Tool Briefing </h2>


                    <span className="style-text">
                        This Tool is related to MDLZ Survey based on PSI and PAI data and user mapping.
                        Basically User can use this tool further to fill the survey related to Parameters /
                        Activites available according to the Filters on Standardization and Automation.
                    </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <h2> Home Page </h2>
                    {/* <Paper style={classes.paper}></Paper> */}
                    <span className="style-text">
                        <p>When user will come on Home page of this tool, they will be able to get some filters related to properties like
                            (Tower, Process, Sub-Process, Region, Cluster and Input Data Type), the data in every filter list will be based on User mapping.</p> <br />

                        <p>If user is assigned or mapped to a Tower named as 'OVH', Process named as 'FPNA', Region named as 'AMEA'/ 'MEA'/ 'NA', or Sub-Process as 'Journals'.
                            They can select the filters based on Input type 'As is' or 'To be' and proceed further for the Survey Page. </p> <br />
                    </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <h2> Survey Page </h2>
                    {/* <Paper style={classes.paper}></Paper> */}
                    <span className="style-text">
                        <p><strong>Process Name:-</strong> Process name will be visible based on the Previous (Home) page selection. </p>
                        <p><strong>Category Selection:-</strong> On this Page, user can select the Category based on Sub-Process selection at the Home Page.</p>
                        <p><strong>Table / Grid:-</strong> A table will be present at the screen which contains columns like Sub-Category based on category selected by the user and Parameters of 'Standardization' and 'Automation'.</p>
                        <p><strong>Export Excel Template:-</strong> User can Export the Template of this Tool and Fill the survey later in Excel file. </p>
                        <p><strong>Ratings Dropdown:-</strong> Select the Rating based on the parameter description in Standardization or Automation. (Options:- 1, 2, 3, 4, NA).</p>
                        <p><strong>Text:-</strong> Required Text / comment field is marked as 'Require *'. User will not able to Submit the Survey until you fill atleast the required fields.</p>
                        <p><strong>Upload Excel File:-</strong> they can login the tool and upload the exported Excel File as well using 'Upload Excel Button'.</p>
                        After uploading the excel file, ensure to check data once in User Interface table.
                        <p><strong>Submit:-</strong> Click on Submit to send the Survey data.</p>

                    </span>
                </Grid>
            </Grid>
        </div>
    );
};
